import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, heroText, logo, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100vh"
          w="100%"
          image={`url(${mobileHero}) top / cover no-repeat`}
          column
        >
          {/* <Header /> */}
          <CFView textCenter column center mt="7%" w="100%">
            <CFView column center>
              <CFImage
                w="70%"
                src={mobileHeroText}
                alt="Bene Sushi hero text"
                zIndex={98}
              />
            </CFView>
            <CFView mt="15px" pulsate>
              <OrderPickupButton />
            </CFView>
            <CFView mt="7px">
              <OrderDeliveryButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="650px"
          w="100%"
          image={`url(${hero}) left / cover no-repeat`}
          zIndex={90}
          column
        >
          <Header />
          <CFView column justifyCenter alignStart ml="6%" h="100%" mt="15px">
            <CFImage
              w="70%"
              maxWidth="400px"
              src={heroText}
              alt="Bene Sushi hero text"
              zIndex={98}
            />
            <CFView mt="30px" pulsate>
              <OrderPickupButton />
            </CFView>
            <CFView mt="10px">
              <OrderDeliveryButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
