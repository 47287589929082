import React, { useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  LocationSelect,
  MobileScreen,
} from 'components'
import { headerBg, logo, logoTitle, mobileLogo, viewMenu } from 'images'
import { dispatch } from 'store'

const orderingUrl =
  process.env.REACT_APP_ENVIRONMENT === 'dev'
    ? 'https://demo.codefusion.tech/restaurants/ebisu/locations/ebisuLocation1'
    : 'https://order.codefusion.tech/restaurants/8GFwb8UTNOQ2rbtqc9kk/locations/Y7GXtSFgqD6z5v9Jpd8q'

const images = []

export default () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <MobileScreen>
        <CFView
          column
          center
          w="100%"
          bg="#06074D"
          boxShadow="0px 2px 4px rgba(0, 0, 0, 0.25)"
          pv="5px"
          h="60px"
        >
          <CFImage
            w="167px"
            src={mobileLogo}
            alt="Bene Sushi Logo"
            top="10px"
          />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          column
          w="100%"
          bg="#06074D"
          boxShadow="0px 2px 4px rgba(0, 0, 0, 0.25);"
          zIndex={98}
          h="55px"
        >
          <CFImage
            ml="6%"
            mt="5px"
            src={logo}
            maxWidth="320px"
            alt="Bene Sushi Logo"
            zIndex={98}
          />
        </CFView>
      </DefaultScreen>
      <ModalGateway>
        {showModal ? (
          <Modal onClose={() => setShowModal(false)}>
            <Carousel views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}
